import {Component, Directive, Injector, OnDestroy} from '@angular/core'
import {ActivatedRoute, RouterOutlet} from '@angular/router'
import {takeUntil} from 'rxjs/operators'
import {Subject} from 'rxjs'
import {LayoutService} from '../../../core/components/layout/layout.service'
import {PageMetadataService} from '../../../core/seo/page-metadata.service'

/**
 * This directive, when added to a router-outlet, will broadcast active events to any service interested.
 * This is useful for instance to know when a navigation occurs and a route is activated in a router / child router
 */
@Directive({
  selector: 'router-outlet[bgoBroadcastActivateEvents]',
})
export class BroadcastActivateEventsDirective implements OnDestroy {
  private readonly unsubscribe$ = new Subject<void>()

  constructor(
    private readonly outlet: RouterOutlet,
    private readonly injector: Injector,
    private readonly layoutService: LayoutService,
    private readonly pageMetadataService: PageMetadataService,
  ) {
    outlet.activateEvents.pipe(takeUntil(this.unsubscribe$)).subscribe(component => {
      if (component) {
        const route = outlet.activatedRoute
        // compute complete route hierarchy
        const hierarchy = this.getRouteAncestorHierarchy(route).concat(component)
        layoutService.applyRouteLayout(route, hierarchy)
        pageMetadataService.applyRouteMetadata(hierarchy)
      }
    })
  }

  getRouteAncestorHierarchy(r: ActivatedRoute): Component[] {
    return r.pathFromRoot.map(r => this.getComponent(r)).filter((c): c is Component => !!c)
  }

  getComponent(r: ActivatedRoute): Component | undefined {
    if (r.component) {
      try {
        return this.injector.get(r.component)
      } catch {
        // ignore injector errors
        return undefined
      }
    }
    return undefined
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
